import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar2/Navbar2'
import PageTitle from '../../components/pagetitle/PageTitle'
import FaqSection from './FaqSection'
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useTranslation } from 'react-i18next';


const FaqPage = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle={t('nav7')} pagesub={t('faqLong')} />
            <section className="wpo-faq-section s2 section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="wpo-section-title-s3">
                            <span>{t('nav7')}</span>
                            <h2>{t('faqLong')}</h2>
                        </div>
                        <div className="col-lg-8 offset-lg-2">
                            <FaqSection />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default FaqPage;

