import React, { useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import API from '../../api';
import { useTranslation } from 'react-i18next';


const FaqSection = (props) => {
    const [faqs, setFaqs] = useState([])
    const {  i18n } = useTranslation();
    const currentLanguage = i18n.language; 

    useEffect(() => {
        // Function to fetch weather data
        const fetchFaqs = async () => {
            try {
                const response = await fetch(`${API}faqs`);
                
                const data = await response.json(); 
                setFaqs(data.data); 
            } catch (error) {
                console.error('Error fetching faqs', error);
            }
        };  
        fetchFaqs();  
    }, []);



    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <div className="wpo-benefits-section">
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="wpo-benefits-item">
                        {faqs.map((faq) => ( 
                            <Accordion expanded={expanded === faq.id} onChange={handleChange(faq.id)}>
                            <AccordionSummary
                                expandIcon={""}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography> {faq[`title_${currentLanguage}`] || faq.title_uz}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {faq[`text_${currentLanguage}`] || faq.text_uz}
                                </Typography>
                            </AccordionDetails>
                            </Accordion>
                        ))}
                        
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FaqSection;