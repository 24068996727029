import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from "react";
import API from "../../api";
import { useTranslation } from "react-i18next";
import "./index.css";
import puzzle from "./puzzle.svg";
import { useParams } from "react-router-dom";

const Practice = (props) => {
  const { id } = useParams();

  console.log(id);

  const [sources, setsources] = useState([]);
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    const fetchsources = async () => {
      try {
        const response = await fetch(`${API}/sources?category=${id}`);
        const data = await response.json();
        setsources(data.data); // Assuming the API returns the desired data
        if (data.data.length > 0) {
          setCategoryName(data.data[0].category_name);
        }
      } catch (error) {
        console.error("Error fetching sources:", error);
      }
    };
    fetchsources();
  }, []);

  const { i18n } = useTranslation();

  return (
    <section className={`wpo-practice-section-s2 ${props.prClass}`}>
      <div class="category-list" id="categories">
        <div class="container">
          <div class="col-md-8">
            <div class="document-categories-content-wrapper">
              <div
                class="doc-content"
                style={{
                  alignItems: "center",
                }}
              >
                <h5
                  style={{
                    color: "#253858",
                    marginBottom: "0.8rem",
                    position: "relative",
                    fontFamily: "Raleway, sans-serif",
                  }}
                >
                  {categoryName.toUpperCase()}
                </h5>
                <div id="w0" class="documents-list">
                  {sources.map((source) => (
                    <div
                      key={source.id}
                      class="document-item"
                      data-key={source.id}
                    >
                      <a
                        href={`${API}uploads/images/${source.src}`}
                        rel="noreferrer"
                        target="_blank"
                        style={{
                          display: "flex",
                          color: "#000",
                          padding: "8px 16px",
                          alignItems: "center",
                        }}
                      >
                        <span
                          class="fa"
                          style={{
                            marginRight: "16px",
                            color: "#0054d1",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22.172"
                            height="24.098"
                          >
                            <g
                              id="doc-icon"
                              transform="translate(-10.023 .098)"
                            >
                              <path
                                id="Path_9"
                                data-name="Path 9"
                                d="M24.959 0h-9.745A2.166 2.166 0 0013 2.114v19.87a2.166 2.166 0 002.214 2.116h15.06a2.166 2.166 0 002.214-2.114V7.187H28.06z"
                                transform="translate(-.294 -.098)"
                                fill="#d7dadd"
                              ></path>
                              <path
                                id="Path_10"
                                data-name="Path 10"
                                d="M40 0v6.558a2.733 2.733 0 002.733 2.733l4.558-2z"
                                transform="translate(-15.214)"
                                fill="#a8adba"
                              ></path>
                              <path
                                id="Path_13"
                                data-name="Path 13"
                                d="M0 24.536A1.536 1.536 0 011.536 23H20.48a1.536 1.536 0 011.536 1.536v9.216a1.536 1.536 0 01-1.536 1.536H1.536A1.536 1.536 0 010 33.752z"
                                transform="translate(10.023 -12.288)"
                                fill="#175bc1"
                              ></path>
                              <path
                                id="Path_14"
                                data-name="Path 14"
                                d="M9.195 31.3h1.791a2.472 2.472 0 011.132.248 1.794 1.794 0 01.766.687 2.084 2.084 0 010 2.072 1.831 1.831 0 01-.766.693 2.524 2.524 0 01-1.132.242H9.195zm1.745 3.193a1.322 1.322 0 00.941-.326 1.168 1.168 0 00.355-.9 1.15 1.15 0 00-.355-.89 1.309 1.309 0 00-.937-.332h-.836v2.445z"
                                transform="translate(5.517 -16.332)"
                                fill="#fff"
                              ></path>
                              <path
                                id="Path_15"
                                data-name="Path 15"
                                d="M19.955 35.245a2.317 2.317 0 01-1.11-.265 2 2 0 01-.771-.727 2.022 2.022 0 01-.276-1.048 1.963 1.963 0 011.048-1.774 2.317 2.317 0 011.11-.265 2.285 2.285 0 011.1.265 1.984 1.984 0 01.771.732 2.036 2.036 0 01-.771 2.816 2.285 2.285 0 01-1.101.266zm0-.777a1.269 1.269 0 00.631-.158 1.152 1.152 0 00.436-.451 1.388 1.388 0 000-1.309 1.113 1.113 0 00-.436-.445 1.3 1.3 0 00-1.262 0 1.152 1.152 0 00-.445.445 1.432 1.432 0 000 1.309 1.192 1.192 0 00.445.451 1.269 1.269 0 00.63.158z"
                                transform="translate(1.3 -16.268)"
                                fill="#fff"
                              ></path>
                              <path
                                id="Path_16"
                                data-name="Path 16"
                                d="M29.214 35.245a2.3 2.3 0 01-1.091-.259 2.008 2.008 0 01-.766-.727 2.036 2.036 0 01-.276-1.053 2 2 0 01.276-1.048 1.953 1.953 0 01.766-.727 2.431 2.431 0 012.022-.084 1.85 1.85 0 01.7.518l-.586.541a1.259 1.259 0 00-.991-.462 1.3 1.3 0 00-.654.164 1.141 1.141 0 00-.451.445 1.432 1.432 0 000 1.309 1.181 1.181 0 00.451.451 1.335 1.335 0 00.654.158 1.254 1.254 0 00.99-.471l.586.541a1.826 1.826 0 01-.7.524 2.317 2.317 0 01-.931.177z"
                                transform="translate(-3.247 -16.268)"
                                fill="#fff"
                              ></path>
                            </g>
                          </svg>
                        </span>
                        <span class="document-name"> {source.title}</span>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Practice;
