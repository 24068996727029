import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "../../api";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {  A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";


const BlogSection2 = (props) => {
  const [blogs, setblogs] = useState([]);
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    // Function to fetch weather data
    const fetchblogs = async () => {
      try {
        const response = await fetch(`${API}blogs?limit=6`);

        const data = await response.json();
        setblogs(data.data);
      } catch (error) {
        console.error("Error fetching services", error);
      }
    };

    fetchblogs();
  }, []);

  const swiperParams = {
    modules: [Pagination, Navigation, Autoplay],
    spaceBetween: 0,
    slidesPerView: 3,
    // loop: true,
    speed: 1000,
    scrollbar: { draggable: true },
    autoplay: { delay: 2000, disableOnInteraction: false },
    parallax: true,
    pagination: { clickable: true },
    breakpoints: {
      250: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-blog-section section-padding">
      <div className="container">
        <div className="wpo-blog-wrap">
          <div className="wpo-section-title-s2">
            <span>{t("blogTitle")}</span>
            <h2>{t("blogText")}</h2>
          </div>
          <div className="wpo-blog-items">
            <div className="row">
              <Swiper {...swiperParams}>
                {blogs.map((blog, Bitem) => (
                  <SwiperSlide key={blog.id}>
                    <div className="col col-12" key={blog.id}>
                      <div className="wpo-blog-item">
                        <div className="wpo-blog-img">
                          <img
                            src={`${API}uploads/images/${blog.blog_images[0]["image.src"]}`}
                            alt={blog.title_uz}
                            style={{
                              height: "250px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <div className="wpo-blog-content">
                          <ul>
                            <li>
                              {moment(blog.created_at).format("YYYY-MM-DD")}
                            </li>
                            <li>
                              <Link
                                onClick={ClickHandler}
                                to={`/blog-single-left-sidebar/${blog.id}`}>
                                {blog.author}
                              </Link>
                            </li>
                          </ul>
                          <h2>
                            <Link
                              onClick={ClickHandler}
                              to={`/blog-single-left-sidebar/${blog.id}`}>
                              {blog[`title_${currentLanguage}`] ||
                                blog.title_uz}
                            </Link>
                          </h2>
                          <Link
                            onClick={ClickHandler}
                            className="b-btn"
                            to={`/blog-single-left-sidebar/${blog.id}`}>
                            {t("readMore")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                ...
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection2;
