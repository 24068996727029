import React from 'react'
import ConsultingForm from '../ConsultingForm/ConsultingForm';
import { useTranslation } from 'react-i18next';


const Consultinencey = (props) => {
    const { t } = useTranslation();

    return (
        <section className={`wpo-contact-section-s2 section-padding ${props.cnClass}`}>
            <div className="container">
                <div className="wpo-contact-section-wrapper">
                    <div className="row justify-content-center">
                        <div className="col col-xl-5 col-lg-6">
                            <div className="contact-info-wrap">
                                <div className="contact-info-title">
                                    <h3>{t('contactInfo')}</h3>
                                    <p>{t('contacText')}</p>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fi flaticon-001-house"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h4>{t('officeAdressTitle')}</h4>
                                        <p>{t('officeAdress')}</p>
                                    </div>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fi flaticon-002-open"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h4>{t('emailTitle')}</h4>
                                        <p><a style={
                                            {
                                                color: '#fff'
                                            }
                                        } href='mailto:loyalxat@gmail.com'>loyalxat@gmail.com</a></p>
                                    </div>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fi flaticon-003-headphone"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h4>{t('phoneTitle')}</h4>
                                        <p><a style={
                                            {
                                                color: '#fff'
                                            }
                                        } href='tel:+998901319544'>+998901319544</a></p>
                                    </div>
                                </div>
                                <div className="contact-info-item">
                                    <div className="contact-info-icon">
                                        <i className="fi flaticon-004-clock"></i>
                                    </div>
                                    <div className="contact-info-text">
                                        <h4>{t('workTimeTitle')}</h4>
                                        <p>{t('fromTo')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-5 col-lg-6 col-md-12 col-12">
                            <div className="wpo-contact-form-area">
                                <div className="form-title">
                                    <span>{t('contactUs')}</span>
                                    <h3>{t('freeConsulting')}</h3>
                                </div>
                                <ConsultingForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Consultinencey;