import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import BlogSingle from '../../components/BlogDetails/BlogDetails2';
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom' 
import Navbar from '../../components/Navbar2/Navbar2';
import Footer from '../../components/footer/Footer';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react'
import API from '../../api';
import i18next from 'i18next';

const BlogDetailsLeftSiide = () => {
    const [blog, setblogs] = useState([])

    const {t} = useTranslation()
    const currentLanguage = i18next.language
    const { slug } = useParams() 

    useEffect(() => {
        // Function to fetch weather data
        const fetchblogs = async () => {
            try {
                const response = await fetch(`${API}blogs/${slug}`);
                
                const data = await response.json(); 
                
                setblogs(data.data); 
            } catch (error) {
                console.error('Error fetching blogs', error);
            }
        }; 

        fetchblogs(); 
    }, [slug]); 
 

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle={t('nav5')} pagesub= {blog[`title_${currentLanguage}`] || blog.title_uz}  />
            <BlogSingle blLeft={'order-lg-1'} blRight={'order-lg-2'} />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default BlogDetailsLeftSiide;


