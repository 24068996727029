import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import weatherIcon from "../../images/cloudy-day.png";
import uk from "../../images/uk.png";
import turkey from "../../images/turkey.png";
import uzbekistan from "../../images/uzbekistan.png";
import china from "../../images/china.png";
import russia from "../../images/russia.png";
import instagram from "./instagram.svg";
import facebook from "./facebook.svg"; 
import i18next from "i18next";
import logo from "./1.png";

import "./index.css"; 
import { Link } from "react-router-dom";

const flagImages = {
  uz: uzbekistan,
  en: uk,
  zh: china,
  ru: russia,
  tr: turkey,
};

const HeaderTopbar = () => {
   
  const { t, i18n } = useTranslation();


  const changeLanguage = (selectedOption) => {
    console.log(selectedOption);
    i18n.changeLanguage(selectedOption);
  };

  return (
    <div className="topbar">
      <div className="container">
        <div className="topbar-list">
          <div>
            <Link className="logo_link" to="/">
            <img
              style={{width: "90px", height:"90px"}}
              alt="main log"
              className="topbar-image"
              src={logo}
            />
            </Link>
          </div>
          <div className="col topbar-item">
            <div className="topbar-item-list">
              <div className="topbar-items">
                <a href="https://t.me/uz_loyal" rel='noreferrer' target='_blank'>
                  <img
                    src="https://fbs-tax.com/wp-content/uploads/2023/02/telegram.svg"
                    alt="telegram icon"
                    className="topbar-icon"
                  />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61554269997134&mibextid=ZbWKwL" rel='noreferrer' target='_blank'>
                  <img
                    src={facebook}
                    alt="facebook icon"
                    className="topbar-icon"
                  />
                </a>
                <a rel='noreferrer' target='_blank' href="https://instagram.com/advokat_uzbekistan_24"  >
                  <img
                    src={instagram}
                    alt="instgram icon"
                    className="topbar-icon"
                  />
                </a>
                <a className="topbar-phone" href="tel:+998901319544">
                  +998 90 131 95 44
                </a>
              </div>
              <span className="topbar-date"> {t('fromTo')}</span>

              <div className="contact-info">
                {Object.keys(flagImages).map((language) => (
                  <img
                    key={language}
                    src={flagImages[language]}
                    alt={language}
                    onClick={() => changeLanguage(language)}
                    style={{
                      cursor: "pointer",
                      marginRight: "8px",
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="topbar-item-list topbar-item-lists ">
              <Link style={{
                fontWeight: "bold",
              }} to='/contact' class="topbar-button">{t('getConsultation')}</Link>
              <Link to={'/'} className="topbar-link" >
                {t('nav1')}
              </Link>
              <a href='about' className="topbar-link" >
                {t('nav2')}
              </a>
              <Link to={'/contact'} className="topbar-link" >
                {t('nav6')}
              </Link>
              <Link to={'/blog'} className="topbar-link" >
                {t('nav4')}
              </Link>
              {/* <Link className="topbar-links" to="https://t.me/uz_loyal">
                <img
                  src="https://fbs-tax.com/wp-content/themes/fbstax-theme/img/telegram-green-200.png"
                  className="topbar-icon"
                  alt=""
                />
                {t('telegramChannel')}
              </Link> */}
              <div  className="topbar-input">

              </div>
              {/* <input
                className="topbar-input"
                type="search"
                placeholder="Search..."
              /> */}
            </div>
          </div>

          {/* <div className="col col-lg-5 col-sm-12 col-12">
            <div className="contact-info">
              {Object.keys(flagImages).map((language) => (
                <img
                  key={language}
                  src={flagImages[language]}
                  alt={language}
                  onClick={() => changeLanguage(language)}
                  style={{
                    cursor: "pointer",
                    marginRight: "8px",
                  }}
                />
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HeaderTopbar;
