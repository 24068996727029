import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API from '../../api';



const Practice3 = (props) => {

    const [services, setServices] = useState([])
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language; 

    
    useEffect(() => { 
        const fetchServices = async () => {
            try {
                const response = await fetch(`${API}services?limit=6`);
                
                const data = await response.json(); 
                setServices(data.data); 
            } catch (error) {
                console.error('Error fetching services', error);
            }
        };  

        fetchServices(); 
    }, []);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (

        <section id='services'  className={`wpo-practice-section-s3 ${props.pClass}`}>
            <div className="container">
                <div className="wpo-section-title-s2">
                    <span>{t('servicesTitle')}</span>
                    <h2>{t('servicesDescription')}</h2>
                </div>
                <div className="wpo-practice-wrap">
                    <div className="row"> 
                        {services.map((practice, Pitem) => (
                            <div className="col-lg-4 col-md-6 col-12" key={Pitem}>
                                <div className="wpo-practice-item">
                                    <div className="">
                                        <img src={`${API}uploads/images/${practice.image_src}`} alt={practice.text_uz}  style={{
                                            height: '220px',
                                            width: '100%',
                                            objectFit: 'cover'
                                            }}></img>
                                    </div>
                                    <div className="wpo-practice-content">
                                        <h2><Link onClick={ClickHandler} to={`/practice-single/${practice.id}`}> {practice[`title_${currentLanguage}`] || practice.title_uz}</Link></h2>
                                        <p>{practice.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}


export default Practice3;