import React, { Fragment } from "react";
import Hero4 from "../../components/hero4/hero4";
import About from "../../components/about/about";
import NewsSection from "../../components/BlogSection/BlogSection";

import BlogSection2 from "../../components/BlogSection2/BlogSection2";
import Navbar2 from "../../components/Navbar2/Navbar2";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Practice3 from "../../components/PracticeS3/PracticeS3";
import Footer from "../../components/footer/Footer";
import FunFact from "../../components/FunFact";
import Consultinencey from "../../components/Consultinencey/Consultinencey";
import Attorney2 from "../../components/attorneys2/attorneys2";
import abimg from "../../images/about/about5.jpg";

const HomePage4 = () => {
  return (
    <Fragment>
      <Navbar2 />
      <Hero4 />
      <FunFact />
      <BlogSection2 />
      <About abClass={"wpo-about-section-s4"} abimg={abimg} /> 
      <NewsSection /> 
      <Consultinencey cnClass={"light-bg"} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage4;
