import uzb from './uz.json';
import rus from './ru.json';
import turkey from './tr.json';
import ch from './zh.json';
import eng from './en.json';



const resources =  { 
     ru: {translation: rus},
     uz: {translation : uzb},
     tr: {translation: turkey},
     en: {translation: eng},
     zh: {translation: ch}
}

export default resources