import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";
import puzzle from "./puzzle.svg";

const Practice = (props) => {
  const { i18n } = useTranslation();
  const [plenum, setplenum] = useState([]);
  const [qonunlar, setQonun] = useState([]);
  const [moddalar, setModda] = useState([]);

  useEffect(() => {
    // Import all files from the 'files' folder
    const filesContext = require.context("./docs/plenium", false);
    const files = importAll(filesContext);

    setplenum(files);

    const qonunContext = require.context("./docs/qonun", false);
    const qonun = importAll(qonunContext);

    setQonun(qonun);

    const moddaContext = require.context("./docs/qaror", false);
    const stat = importAll(moddaContext);

    setModda(stat);
  }, []);

  return (
    <section className={`wpo-practice-section-s2 ${props.prClass}`}>
      <div class="category-list" id="categories">
        <div class="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="category-card has-image"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  padding: "2.5rem",
                  marginBottom: "32px",
                  height: "calc(100% - 32px)",
                  transition: "all .3s ease-in-out",
                }}
              >
                <div
                  class="category-image-wrap"
                  style={{
                    width: "56px",
                    height: "56px",
                    marginTop: "8px",
                    float: "left",
                    marginRight: "16px",
                  }}
                >
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    src={puzzle}
                    alt="puzzle"
                    class="category-image"
                  />
                </div>
                <div
                  className="card-content"
                  style={{
                    marginLeft: "80px",
                    marginTop: "0",
                    paddingTop: "0",
                    paddingBottom: "0",
                  }}
                >
                  <h4
                    className="h4 category-name"
                    style={{
                      color: "#253858",
                      marginBottom: "0.8rem",
                      position: "relative",
                      fontFamily: "'Raleway', sans-serif",
                    }}
                  >
                    Қонунлар
                  </h4>
                  <ul className="category-post-list">
                    {qonunlar.map((file, index) => (
                      <li key={index}>
                        <span className="ti-arrow-right"></span>{" "}
                        <a href={file}>{getFileNameAfterThirdSlash(file)}</a>
                      </li>
                      // <li key={index}>{file.default}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="category-card has-image"
                style={{
                  backgroundColor: "#fff",
                  borderRadius: "7px",
                  padding: "2.5rem",
                  marginBottom: "32px",
                  height: "calc(100% - 32px)",
                  transition: "all .3s ease-in-out",
                }}
              >
                <div
                  class="category-image-wrap"
                  style={{
                    width: "56px",
                    height: "56px",
                    marginTop: "8px",
                    float: "left",
                    marginRight: "16px",
                  }}
                >
                  <img
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                    src={puzzle}
                    alt="puzzle"
                    class="category-image"
                  />
                </div>
                <div
                  className="card-content"
                  style={{
                    marginLeft: "80px",
                    marginTop: "0",
                    paddingTop: "0",
                    paddingBottom: "0",
                  }}
                >
                  <h4
                    className="h4 category-name"
                    style={{
                      color: "#253858",
                      marginBottom: "0.8rem",
                      position: "relative",
                      fontFamily: "'Raleway', sans-serif",
                    }}
                  >
                    Олий суд Пленум қарорлари
                  </h4>
                  <ul className="category-post-list">
                    {plenum.map((file, index) => (
                      <li key={index}>
                        <span className="ti-arrow-right"></span>{" "}
                        <a href={file}>{getFileNameAfterThirdSlash(file)}</a>
                      </li>
                      // <li key={index}>{file.default}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Practice;

const importAll = (context) => {
  const keys = context.keys();
  return keys.map(context);
};

const getFileNameAfterThirdSlash = (path) => {
  const parts = path.split("/");
  let data = parts.length >= 4 ? parts[3] : null;

  return removePartBetweenDots(data); // Check if there are at least 4 parts
};

const removePartBetweenDots = (path) => {
  const parts = path.split(".");
  if (parts.length >= 3) {
    parts[parts.length - 2] = ""; // Set the part between the last two dots to an empty string
    return parts.join(".");
  } else {
    return null;
  }
};
