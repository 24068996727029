import cimg1 from '../images/case/img-1.jpg'
import cimg2 from '../images/case/img-2.jpg'
import cimg3 from '../images/case/img-3.jpg'
import cimg4 from '../images/case/img-4.jpg'
import cimg5 from '../images/case/img-5.jpg'
import cimg6 from '../images/case/img-6.jpg'
import cimg7 from '../images/case/img-7.jpg'
import cimg8 from '../images/case/img-8.jpg'
import cimg9 from '../images/case/img-9.jpg'
import cimg10 from '../images/case/img-10.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'


const Cases = [
    {
        Id: '1',
        cTitle: 'Criminal Law', 
        slug: 'Criminal-Law',
        cImg:cimg1,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        cTitle: 'Family Law', 
        slug: 'Family-Law',
        cImg:cimg2,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        cTitle: 'Real Estate', 
        slug: 'Real-Estate',
        cImg:cimg3,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        cTitle: 'Business Law', 
        slug: 'Business-Law',
        cImg:cimg4,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        cTitle: 'Criminal Law', 
        slug: 'Criminal-Laws',
        cImg:cimg5,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        cTitle: 'Family Law', 
        slug: 'Famil-Laws',
        cImg:cimg6,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '7',
        cTitle: 'Business Law', 
        slug: 'Business-Laws',
        cImg:cimg7,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '8',
        cTitle: 'Divorce law', 
        slug: 'Divorce-law',
        cImg:cimg8,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    
    {
        Id: '9',
        cTitle: 'Real Estate', 
        slug: 'Real-Estate-Law',
        cImg:cimg9,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    
    {
        Id: '10',
        cTitle: 'Family Law', 
        slug: 'Famil-Lawss',
        cImg:cimg10,
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
]

export default Cases;