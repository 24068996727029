import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import API from "../../api";
import "./index.css";
import puzzle from "./puzzle.svg";

const Practice = (props) => {
  const [sources, setSources] = useState([]);

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const response = await fetch(`${API}/sources`);
        const data = await response.json();
        setSources(data.data); // Assuming the API returns the desired data
      } catch (error) {
        console.error("Error fetching sources:", error);
      }
    };
    fetchSources();
  }, []);

  let groupedData = groupSourcesByCategory(sources);
  console.log(groupedData);

  const { i18n } = useTranslation();

  const renderCategory = (parentCategoryName, categories) => (
    <div className="col-lg-6">
      <div
        className="category-card has-image"
        style={{
          backgroundColor: "#fff",
          borderRadius: "7px",
          padding: "2.5rem",
          marginBottom: "32px",
          height: "calc(100% - 32px)",
          transition: "all .3s ease-in-out",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          class="category-image-wrap"
          style={{
            width: "56px",
            height: "56px",
            marginTop: "8px",
            float: "left",
            marginRight: "16px",
          }}
        >
          <img
            style={{
              width: "50px",
              height: "50px",
            }}
            src={puzzle}
            alt="puzzle"
            class="category-image"
          />
        </div>
        <div
          className="card-content"
          style={{
            marginLeft: "80px",
            marginTop: "0",
            paddingTop: "0",
            paddingBottom: "0",
          }}
        >
          <h4
            className="h4 category-name"
            style={{
              color: "#253858",
              marginBottom: "0.8rem",
              position: "relative",
              fontFamily: "'Raleway', sans-serif",
            }}
          >
            {parentCategoryName}
          </h4>
          <ul className="category-post-list">
            {categories.map((category) => (
              <li key={category.id}>
                <span className="ti-arrow-right"></span>{" "}
                <a href={`/category/${category.category_id}`}>
                  {category.category_name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <section className={`wpo-practice-section-s2 ${props.prClass}`}>
      <div class="category-list" id="categories">
        <div class="container">
          <div className="row">
            {Object.keys(groupedData).map((parentCategory) =>
              renderCategory(parentCategory, groupedData[parentCategory])
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

function groupSourcesByCategory(sources) {
  const groupedSources = {};

  sources.forEach((source) => {
    const parentCategoryName = source.parent_category_name || "Uncategorized";

    if (!groupedSources[parentCategoryName]) {
      groupedSources[parentCategoryName] = [];
    }

    const { category_id, category_name, ...rest } = source;

    // Check if the combination of category_id and category_name exists in the array
    const exists = groupedSources[parentCategoryName].some(
      (item) =>
        item.category_id === category_id && item.category_name === category_name
    );

    if (!exists) {
      groupedSources[parentCategoryName].push({
        category_id,
        category_name,
        ...rest,
        // Add other properties you want to include
      });
    }
  });

  return groupedSources;
}

export default Practice;
