import atimg1 from '../images/team/img-1.jpg'
import atimg2 from '../images/team/img-2.jpg'
import atimg3 from '../images/team/img-3.jpg'
import atimg4 from '../images/team/img-4.jpg'


const Attorneys = [
   {
      Id: '1',
      AtImg: atimg1,
      name: 'Robert Fox',
      slug: 'Robert-Fox',
      title: 'Family Lawyer',
   },
   {
      Id: '2',
      AtImg: atimg2,
      name: 'Jane Cooper',
      slug: 'Jane-Cooper',
      title: 'Divorce Lawer',
   },
   {
      Id: '3',
      AtImg: atimg3,
      name: 'Jacob Jones',
      slug: 'Jacob-Jones',
      title: 'Criminal Lawer',
   },
   {
      Id: '4',
      AtImg: atimg4,
      name: 'Jerome Bell',
      slug: 'Jerome-Bell',
      title: 'Family Lawyer',
   },
   {
      Id: '5',
      AtImg: atimg2,
      name: 'Jane Cooper',
      slug: 'Jane-Cooper',
      title: 'Divorce Lawer',
   }

]

export default Attorneys;