import React from 'react';
import { Link } from 'react-router-dom' 
import gl1 from '../../images/blog/img-3.jpg' 
import { useParams } from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react'
import API from '../../api';
import i18next from 'i18next';
import moment from 'moment';

const BlogSingle = (props) => {

    const [news, setNews] = useState([])
    const [two, setTwo] = useState([])



    const {t} = useTranslation()
    const currentLanguage = i18next.language
    const { slug } = useParams() 

    useEffect(() => {
        // Function to fetch weather data
        const fetchNews = async () => {
            try {
                const response = await fetch(`${API}blogs/${slug}`);
                
                const data = await response.json(); 
                
                setNews(data.data); 
            } catch (error) {
                console.error('Error fetching services', error);
            }
        }; 

        const fetchTwo = async () => {
            try {
                const response = await fetch(`${API}blogs?limit=2`);
                
                const data = await response.json(); 
                
                setTwo(data.data); 
            } catch (error) {
                console.error('Error fetching services', error);
            }
        }; 

        fetchNews(); 
        fetchTwo()
    }, [slug]); 

     
 

    return (
        <section className="wpo-blog-single-section section-padding">
            <div className="container">
                <div className="row">
                <BlogSidebar blLeft={props.blLeft}/>
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-meta">
                                    <ul>
                                        <li><i className="fi flaticon-user"></i> By <Link to={`/blog-single-left-sidebar/${news.id}`} >{news?.author}</Link> </li>
                                        {/* <li><i className="fi flaticon-comment-white-oval-bubble"></i> Comments {BlogDetails?.comment}</li> */}
                                        <li><i className="fi flaticon-calendar"></i> {moment(news.created_at).format('YYYY-MM-DD')}</li>
                                    </ul>
                                </div>
                                <h2> {news[`title_${currentLanguage}`] || news.title_uz}</h2>
                                <p>{news[`text_${currentLanguage}`] || news.text_uz}</p>
                                {/* <blockquote>
                                    Combined with a handful of model sentence structures, generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                                </blockquote>
                                <p>I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself,</p> */}

                                <div className="gallery">
                                   {
                                    news.blog_images ?  news["blog_images"].map((item, id) => (
                                        <div>
                                            <img src={`${API}uploads/images/${item['image.src']}`} alt="" />
                                        </div> 
                                        )) 
                                        : <div>
                                        <img src={gl1} alt="" />
                                    </div> 
                                   }
                                </div>
                            </div>

                            <div className="tag-share clearfix">
                                <div className="tag">
                                    <span>Share: </span>
                                    <ul>
                                        <li><Link to="/blog-single/Justice-May-For-You-If-You-Are-Innocen">Planning</Link></li>
                                        <li><Link to="/blog-single/Justice-May-For-You-If-You-Are-Innocen">Business</Link></li>
                                        <li><Link to="/blog-single/Justice-May-For-You-If-You-Are-Innocen">Consulting</Link></li>
                                    </ul>
                                </div>
                            </div>
                          

                           

                            <div className="more-posts">
                                <div className="previous-post">
                                    <Link to={two && two.length >1 ?
                                        `/blog-single-left-sidebar/${two[0]['id']}`
                                        : '/'  
                                    }>
                                        <span className="post-control-link">{t('previous')}</span>
                                        <span className="post-name">{two && two.length >1 ?
                                        two[0][`title_${currentLanguage}`] || two[0].title_uz  
                                        : ''  
                                    }</span>
                                    </Link>
                                </div>
                                <div className="next-post">
                                    <Link to={two && two.length >1 ?
                                        `/blog-single-left-sidebar/${two[1]['id']}`
                                        : '/'  
                                    }>
                                        <span className="post-control-link">{t('next')}</span>
                                        <span className="post-name">{two && two.length  >1 ?
                                        two[1][`title_${currentLanguage}`] || two[1].title_uz  
                                        : ''  
                                    }</span>
                                    </Link>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                   
                </div>
            </div>
        </section>
    )

}

export default BlogSingle;
