import React from 'react' 
import { useTranslation } from 'react-i18next';


const About = (props) => {
    const { t } = useTranslation();

    return (
        <section id='about' className={`wpo-about-section-s3 section-padding  ${props.abClass}`}>
            <div className="container">
                <div className="wpo-section-title-s2">
                    <span>{t('aboutTitle')}</span>
                    <h2>{t('aboutMain')}</h2>
                </div>
                <div className="wpo-about-section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12 col-12">
                            <div className="wpo-about-content">
                                <h2>{t('aboutMiddleTitle')}</h2>
                                <div className="wpo-about-content-inner">
                                    <p style={{textAlign: 'justify'}}>&nbsp;&nbsp;&nbsp;{t("aboutText")} </p> 
                                    <p style={{textAlign: 'justify'}}>&nbsp;&nbsp;&nbsp;{t("lastParagraph")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={props.abimg} alt="" />
                                {/* <div className="about-img-inner">
                                    <img src={abimg2} alt="" />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="visible-text">
                        <h2>About</h2>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;