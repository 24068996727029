import React from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

const FunFact = (props) => {
  const { t } = useTranslation();

  const funFact = [
    {
      title: "150",
      subTitle: `${t("trustedClients")}`,
      Symbol: "+",
    },
    {
      title: "95",
      subTitle: `${t("casesWon")}`,
      Symbol: "%",
    },
    {
      title: "20",
      subTitle: `${t("lawyers")}`,
      Symbol: "+",
    },
  ];

  return (
    <section className={`wpo-fun-fact-section content ${props.fnClass}`}>
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="wpo-fun-fact-grids-wrap">
              <div className="wpo-fun-fact-grids clearfix d-flex justify-content-around">
                {funFact.map((funfact, fitem) => (
                  <div className="grid" key={fitem}>
                    <div className="info">
                      <h3>
                        <span>
                          <CountUp end={funfact.title} enableScrollSpy />
                        </span>
                        {funfact.Symbol}
                      </h3>
                      <p>{funfact.subTitle}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <span id="counter" className="d-none" />
    </section>
  );
};

export default FunFact;
