import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import HeaderTopbar from "../HeaderTopbar";
import API from "../../api";
import { useTranslation } from "react-i18next";
import "./index.css";

const Header2 = (props) => {
  const [services, setServices] = useState([]);
  const [resources, setresources] = useState([]);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    // Function to fetch weather data
    const fetchServices = async () => {
      try {
        const response = await fetch(`${API}services`);

        const data = await response.json();
        setServices(data.data);
      } catch (error) {
        console.error("Error fetching services", error);
      }
    };

    const fetchResources = async () => {
      try {
        const response = await fetch(`${API}sources`);

        const data = await response.json();
        console.log(data.data);
        setresources(data.data);
      } catch (error) {
        console.error("Error fetching services", error);
      }
    };
    fetchServices();
    fetchResources();
  }, []);

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
     <header
      id="header"
      className="wpo-header-s4"
      style={{
        position: "relative",
      }}>
      <HeaderTopbar />
      <div className="wpo-site-header wpo-header-style-4">
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container">
            <div className="row align-items-center">
              <div className="col col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col col-lg-12 col-md-12 col-12">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder">
               
                  <ul className="nav navbar-nav mb-2 col-12 mb-lg-0">
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/news">
                        {t("nav5")}
                      </Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/library">
                        {t("library")}
                      </Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link onClick={ClickHandler} to="/services">
                        {t("nav3")}
                      </Link>
                      <ul className="sub-menu">
                        {services.map((service) => (
                          <li key={service.id}>
                            <Link
                              onClick={ClickHandler}
                              to={`/practice-single/${service.id}`}>
                              {service[`title_${currentLanguage}`] ||
                                service.title_uz}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li> 
                    <li>
                      <Link onClick={ClickHandler} to="/resources">
                        {t("nav8")}
                      </Link>
                      {/* <ul className="sub-menu">
                        {resources.map((resource) => (
                          <li className="sub-menu-list" key={resource.id}>
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={`${API}uploads/images/${resource.src}`}>
                              {resource.title}
                            </a>
                          </li>
                        ))}
                      </ul> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps, { removeFromCart })(Header2);
