import simg from '../images/practice/img-2.jpg'
import simg2 from '../images/practice/img-1.jpg'
import simg3 from '../images/practice/img-3.jpg'
import simg4 from '../images/practice/img-4.jpg'
import simg5 from '../images/practice/img-5.jpg'
import simg6 from '../images/practice/img-6.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'

const Practices = [
    {
        Id: '1',
        sImg:simg,
        sTitle: 'Family Law',
        slug: 'Family-Law',
        description:'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2:'The lower-numbered purposes are better understood and practiced',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-012-family',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        sImg:simg2,
        sTitle: 'Personal Injury',
        slug: 'Personal-Injury',
        description:'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2:'The lower-numbered purposes are better understood and practiced',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-015-patient',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg3,
        sTitle: 'Criminal Law',
        slug: 'Criminal-Law',
        description:'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2:'The lower-numbered purposes are better understood and practiced',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-016-gun',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImg:simg4,
        sTitle: 'Education Law',
        slug: 'Education-Law',
        description:'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2:'The lower-numbered purposes are better understood and practiced',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-014-learning',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '5',
        sImg:simg5,
        sTitle: 'Business Law',
        slug: 'Business-Law',
        description:'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2:'The lower-numbered purposes are better understood and practiced',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-013-planning',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '6',
        sImg:simg6,
        sTitle: 'Real Estate Law',
        slug: 'Real-Estate-Law',
        description:'We ca help you when you ipsum dolor sit amet, constur adipiscing elit. Ultrices cursus sitting scelerisque quam nisl.',
        des2:'The lower-numbered purposes are better understood and practiced',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-017-agreement',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
]
export default Practices;