import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage4 from "../HomePage4/HomePage4";
import AboutPage from "../AboutPage/AboutPage";
import PricingPage from "../PricingPage/PricingPage";
import AttorneysPage from "../AttorneysPage/AttorneysPage";
import AttorneySinglePage from "../AttorneySinglePage/AttorneySinglePage";
import PracticeSinglePage from "../PracticeSinglePage/PracticeSinglePage";
import CaseSinglePage from "../CaseSinglePage/CaseSinglePage";
import ShopPage from "../ShopPage";
import ProductSinglePage from "../ProductSinglePage";
import CartPage from "../CartPage";
import CheckoutPage from "../CheckoutPage";
import OrderRecived from "../OrderRecived";
import BlogPage from "../BlogPage/BlogPage";
import BlogPageLeft from "../BlogPageLeft/BlogPageLeft";
import BlogPageFullwidth from "../BlogPageFullwidth/BlogPageFullwidth";
import BlogDetails from "../BlogDetails/BlogDetails";
import BlogDetailsFull from "../BlogDetailsFull/BlogDetailsFull";
import BlogDetailsLeftSiide from "../BlogDetailsLeftSiide/BlogDetailsLeftSiide";
import TermPage from "../TermPage/TermPage";
import PrivacyPage from "../PrivacyPage/PrivacyPage";
import ContactPage from "../ContactPage/ContactPage";
import ErrorPage from "../ErrorPage/ErrorPage";
import LoginPage from "../LoginPage";
import SignUpPage from "../SignUpPage";
import ForgotPassword from "../ForgotPassword";
import FaqPage from "../FaqPage";
import PracticePage from "../PracticePage/PracticePage";
import PracticePageS2 from "../PracticePageS2/PracticePageS2";
import PracticePageS3 from "../PracticePageS3/PracticePageS3";
import Library from "../Library/PracticePageS2";

import CasePage from "../CasePage/CasePage";
import CategorySource from "../CategorySource/Category";
 

import CasePageS3 from "../CasePageS3/CasePageS3";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage4 />} />
          <Route path="home" element={<HomePage4 />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="attorneys" element={<AttorneysPage />} />
          <Route path="case-single/:slug" element={<CaseSinglePage />} />
          <Route
            path="attorneys-single/:slug"
            element={<AttorneySinglePage />}
          />
          <Route
            path="practice-single/:slug"
            element={<PracticeSinglePage />}
          />
          <Route path="terms" element={<TermPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="library" element={<Library />} />

          <Route path="faq" element={<FaqPage />} />
          <Route path="practice" element={<PracticePage />} />
          <Route path="resources" element={<PracticePageS2 />} />
          <Route path="services" element={<PracticePageS3 />} />
          <Route path="case" element={<CasePage />} /> 
          <Route path="case-s3" element={<CasePageS3 />} />
          <Route path="shop" element={<ShopPage />} />
          <Route path="product-single/:slug" element={<ProductSinglePage />} />
          <Route path="category/:id" element={<CategorySource />} />

          <Route path="cart" element={<CartPage />} />
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="order_received" element={<OrderRecived />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="news" element={<BlogPageLeft />} />
          <Route path="blog-fullwidth" element={<BlogPageFullwidth />} />
          <Route path="news-single/:slug" element={<BlogDetails />} />
          <Route
            path="blog-single-left-sidebar/:slug"
            element={<BlogDetailsLeftSiide />}
          />
          <Route
            path="blog-single-fullwidth/:slug"
            element={<BlogDetailsFull />}
          />
          <Route path="contact" element={<ContactPage />} />
          <Route path="404" element={<ErrorPage />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<SignUpPage />} />
          <Route path="404" element={<ErrorPage />} />

          <Route path="*" element={<ErrorPage />} />

          <Route path="forgot-password" element={<ForgotPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
