import React from 'react'
import { Link } from 'react-router-dom' 
import { useTranslation } from 'react-i18next';
import telegram from './telegram.svg'
import Logo from "../../images/logo3.png"




const Footer = (props) => {
    const { t } = useTranslation();


    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    

    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <Link onClick={ClickHandler} className="navbar-brand d-flex" to="/">
                                        <h1 style={{
                                            color: '#fff'
                                        }}>
                                            LOYAL <br className='mt-2'/>
                                            advokatlik byurosi
                                        </h1> 
                                    </Link>
                                </div>
                                <p> {t('aboutMiddleTitle')}</p>
                                <ul>
                                    <li>
                                        <a target={'_blank'} rel='noreferrer'  onClick={ClickHandler} href="https://www.facebook.com/profile.php?id=61554269997134&mibextid=ZbWKwL">
                                            <i className="ti-facebook"></i>
                                        </a>
                                    </li>
                                    <li> 
                                            <a onClick={ClickHandler} href="https://t.me/uz_loyal" rel='noreferrer' target='_blank' className='d-flex justify-content-center align-items-center'>
                                                <img
                                                    src={telegram}
                                                    alt="telegram icon"
                                                    className="topbar-icon"
                                                />
                                            </a> 
                                    </li> 
                                    <li>
                                        <a  target={'_blank'}  onClick={ClickHandler} rel='noreferrer'  href="https://instagram.com/advokat_uzbekistan_24/">
                                            <i className="ti-instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-2 offset-lg-1 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>{t('quickLinks')}</h3>
                                </div>
                                <ul>
                                       <li className="menu-item-has-children"> 
                                            <Link onClick={ClickHandler} to="/">{t('nav1')}</Link> 
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/about">{t('nav2')}</Link>
                                            
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/services">{t('nav3')}</Link>
                                            
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/blog">{t('nav4')}</Link>
                                             
                                        </li> 
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/news">{t('nav5')}</Link>
                                             
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/resources">{t('nav8')}</Link>
                                             
                                        </li>
                                        <li><Link onClick={ClickHandler} to="/faq">{t('nav7')}</Link></li>
                                        <li><Link onClick={ClickHandler} to="/contact">{t('nav6')}</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget s2">
                                <div className="widget-title">
                                    <h3>{t('nav3')}</h3>
                                </div>
                                <ul> 
                                        <li><Link to={`/contact`}>{t('fLaw')}</Link></li> 
                                        <li><Link to={`/contact`}>{t('personalInjury')}</Link></li> 
                                        <li><Link to={`/contact`}>{t('personalInjury')}</Link></li> 
                                        <li><Link to={`/contact`}>{t('crimLaw')}</Link></li>
                                        <li><Link to={`/contact`}>{t('eduLaw')}</Link></li> 
                                        <li><Link to={`/contact`}>{t('businessLaw')}</Link></li> 
                                        <li><Link to={`/contact`}>{t('realEstateLaw')}</Link></li> 
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-lg-6 col-md-12 col-12">
                            <ul>
                                <li>Copyright &copy; 2023 <Link onClick={ClickHandler} to="/">Loyal.uz</Link>. All rights reserved.</li>
                            </ul>
                        </div>
                        <div className="col col-lg-6 col-md-12 col-12">
                            <div className="link">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/privacy">Privace & Policy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/terms">Terms</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about">About us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;