import React, { useEffect, useState } from "react";
import { A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "../../api";
import "./style.css";
import i18next from "i18next";
import { Autoplay, Pagination, Navigation } from "swiper";

const Hero4 = () => {
  const { t } = useTranslation();
  const [news, setnews] = useState([]);
  const currentLanguage = i18next.language;

  useEffect(() => {
    // Function to fetch weather data
    const fetchNews = async () => {
      try {
        const response = await fetch(`${API}services/`);

        const data = await response.json();

        setnews(data.data);
      } catch (error) {
        console.error("Error fetching services", error);
      }
    };

    fetchNews();
  }, []);

  console.log(news, "news");

  return (
    <section className="hero">
      <div className="container">
        <Swiper
          modules={[Autoplay, Navigation]}
          spaceBetween={40}
          speed={1000}
          rewind={true}
          navigation={true}
          // slidesPerView={4}
          // centeredSlides={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            520: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
        >
          {news.map((blog, index) => (
            <SwiperSlide key={index}>
              <Link to={`/practice-single/${blog.id}`}>
                <div className="slide-content">
                  <img
                    src={`${API}uploads/images/${blog.image_src}`}
                    className="hero-image"
                    alt=""
                    loading="lazy"
                  />
                  <h2 className="hero-title">
                    {blog[`title_${currentLanguage}`] || blog.title_uz}
                  </h2>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Hero4;
