import React, { Fragment, useEffect, useState } from 'react';
import Navbar from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/Footer'; 
import Sidebar from '../../components/BlogSidebar/index';
import FaqSection from '../FaqPage/FaqSection';
import API from '../../api';
import { useTranslation } from 'react-i18next';



const PracticeSinglePage = (props) => {
    const { slug } = useParams()
    const [services, setServices] = useState([])


    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language; 

    useEffect(() => {
        // Function to fetch weather data
        const fetchServices = async () => {
            try {
                const response = await fetch(`${API}services/${slug}`);
                
                const data = await response.json(); 
                setServices(data.data); 
            } catch (error) {
                console.error('Error fetching services', error);
            }
        };  

        fetchServices();  
    }, [slug]);
 

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle={services[`title_${currentLanguage}`] || services.title_uz} pagesub={t('nav3')} />

            <section className="wpo-service-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 order-lg-2 order-1">
                            <div className="wpo-service-single-wrap">
                                <div className="wpo-service-single-content">
                                    <img src={`${API}uploads/images/${services['image_src']}`}  alt="jimi" />
                                    <div className="wpo-service-single-content-des">
                                        <h2>{services[`title_${currentLanguage}`] || services.title_uz}</h2>
                                        <p>{services[`text_${currentLanguage}`] || services.text_uz}</p>
                                         
                                    </div>
                                     
                                </div>
                                <div className="wpo-faq-section">
                                    <h2>{t('questions')}</h2>
                                    
                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <div className="wpo-faq-item">
                                                <FaqSection/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Sidebar/>
                    </div>
                </div>
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default PracticeSinglePage;
