import React from 'react';
import { Link } from 'react-router-dom' 
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react'
import API from '../../api';
import i18next from 'i18next';
import moment from 'moment';


const BlogSidebar = (props) => {
    const [news, setNews] = useState([])
    const [services, setservices] = useState([])



    const {t} = useTranslation()
    const currentLanguage = i18next.language  

    useEffect(() => {
        // Function to fetch weather data
        const fetchNews = async () => {
            try {
                const response = await fetch(`${API}news?limit=5`);
                
                const data = await response.json(); 
                
                setNews(data.data); 
            } catch (error) {
                console.error('Error fetching services', error);
            }
        }; 

        const fetchServices = async () => {
            try {
                const response = await fetch(`${API}services?limit=8`);
                
                const data = await response.json(); 
                
                setservices(data.data); 
            } catch (error) {
                console.error('Error fetching services', error);
            }
        }; 

        console.log(news, services)

        fetchNews(); 
        fetchServices()
    }, []); 


    

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className={`col col-lg-4 col-12 ${props.blLeft}`}>
            <div className="blog-sidebar">
                 
                 
                <div className="widget category-widget">
                    <h3>{t('nav3')}</h3>
                    <ul>
                        {services.map((service) => (
                                    <li key={service.id}>
                                            <Link onClick={ClickHandler} to={`/practice-single/${service.id}`}>
                                                {service[`title_${currentLanguage}`] || service.title_uz}
                                            </Link>
                                    </li>
                        ))}
                        
                    </ul>
                </div>
                <div className="widget recent-post-widget">
                    <h3>{t('relatedPosts')}</h3>
                    <div className="posts">
                        <div className="posts">
                            {news.length ? news.map((blog, Bitem) => (
                                <div className="post" key={blog.id}>
                                    <div className="img-holder">
                                        {
                                            blog[0] &&  blog[0]['image.src'] ? <img src={`${API}uploads/images/${blog[0]['image.src']}`} alt="" /> : ''
                                        }
                                        
                                    </div>
                                    <div className="details">
                                        <h4><Link onClick={ClickHandler} to={`/news-single/${blog.id}`}>{blog[`title_${currentLanguage}`] || blog.title_uz}</Link></h4>
                                        <span className="date">{moment(blog.created_at).format('YYYY-MM-DD')}</span>
                                    </div>
                                </div>
                            )) : ''
                        }
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
    )

}

export default BlogSidebar;
