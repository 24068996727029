import React from 'react';
import ContactForm from '../ContactFrom'
import { useTranslation } from 'react-i18next';



const Contactpage = () => {
    const { t } = useTranslation();


    return (
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-011-maps-and-flags"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>{t('officeAdressTitle')}</h2>
                                            <p>{t('officeAdress')}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-037-email-1"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>{t('emailTitle')}</h2>
                                            <p>loyalxat@gmail.com</p> 
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-009-telephone"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>{t('phoneTitle')}</h2>
                                            <p>+998901319544</p> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wpo-contact-title">
                            <h2>{t('haveQuestion')}</h2>
                            <p>{t('helpYou')}</p>
                        </div>
                        <div className="wpo-contact-form-area">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                <iframe title='loyal.uz address' src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d477.56164031471434!2d69.26126132549456!3d41.297973999454044!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDHCsDE3JzUxLjciTiA2OcKwMTUnNDAuOCJF!5e0!3m2!1sen!2s!4v1702485190153!5m2!1sen!2s" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </section>
        </section>
    )

}

export default Contactpage;
