import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import BlogSingle from '../../components/BlogDetails/BlogDetails'; 
import Footer from '../../components/footer/Footer';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react'
import API from '../../api';
import i18next from 'i18next';



const BlogDetails = () => {
    const [news, setNews] = useState([])

    const {t} = useTranslation()
    const currentLanguage = i18next.language
    const { slug } = useParams() 

    useEffect(() => { 
        // Function to fetch weather data
        const fetchNews = async () => {
            try {
                const response = await fetch(`${API}news/${slug}`);
                
                const data = await response.json(); 
                
                setNews(data.data); 
            } catch (error) {
                console.error('Error fetching services', error);
            }
        }; 

        fetchNews(); 
    }, [slug]);

    // const BlogDetails = blogs.find(item => item.slug === slug)

    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle={t('nav5')} pagesub= {news[`title_${currentLanguage}`] || news.title_uz} />
            <BlogSingle />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default BlogDetails;
