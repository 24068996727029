import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import { useTranslation } from 'react-i18next';


const ContactForm = () => {
    const { t } = useTranslation();


    const [forms, setForms] = useState({
        name: '',
        email: '', 
        phone: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) { 
        let bot = {
            TOKEN: "6476387097:AAFd6xFRZ-PV6Kih3PmWmTbeFhrpC6P6EDU",
            chatID: "5419106874",
            message: `
              Assalomu alaykum. Konsultatsiya uchun so'rov qoldirildi!%0A
              %0AIsmi 👤: ${forms.name};
              %0ATelefon raqami ☎: ${"+"}${forms.phone};
              %0AEmail 📧: ${forms.email}; 
              %0AXabar ✍️: ${forms.message};` 
        };

        fetch(
            `https://api.telegram.org/bot${bot.TOKEN}/sendMessage?chat_id=${bot.chatID}&text=${bot.message} `,
            {
              method: "GET",
            }
          ).then(
            (success) => {

                validator.hideMessages();
                setForms({
                    name: '',
                    email: '', 
                    phone: '',
                    message: ''
            })
            },
            (error) => {
              console.log(error);
            }
          ); 

        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder={t('yourName')} />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder={t('yourEmail')} />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder={t('yourPhone')} />
                        {validator.message('phone', forms.phone, 'required|phone')}
                    </div>
                </div> 
                <div className="col col-lg-12 col-12">
                    <textarea
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder={t('yourCase')}>
                    </textarea>
                    {validator.message('message', forms.message, 'required')}
                </div>
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">{t('submitNow')}</button>
            </div>
        </form >
    )
}

export default ContactForm;