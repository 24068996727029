import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle'
import Category from '../../components/Category';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import { useTranslation } from 'react-i18next';


const PracticePageS2 =() => {
    const { t, i18n } = useTranslation();
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle={t('nav8')} pagesub={t('nav8')}/> 
            <Category  prClass={'section-padding'}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default PracticePageS2;

