import React from 'react'
import {Link} from 'react-router-dom'
import erimg from '../../images/error-404.png'
import { useTranslation } from 'react-i18next';

const Error = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

     const { t } = useTranslation();


    return(
        <section className="error-404-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="content clearfix"> 
                            <div className="error-message">
                                <h3>{t('oops')}</h3>
                                <p>{t('notFoundText')}</p>
                                <Link onClick={ClickHandler} to="/home" className="theme-btn">{t('backToHome')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Error;