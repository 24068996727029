import React from 'react';
import {Link} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar' 
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react'
import API from '../../api';
import i18next from 'i18next';
import moment from 'moment';


const BlogList = (props) => {

    const [blogs, setblogs] = useState([])

    const {t} = useTranslation()
    const currentLanguage = i18next.language 

    useEffect(() => {
        // Function to fetch weather data
        const fetchNews = async () => {
            try {
                const response = await fetch(`${API}news/`);
                
                const data = await response.json(); 
                
                setblogs(data.data); 
            } catch (error) {
                console.error('Error fetching blogs', error);
            }
        };  

        fetchNews();  
    }, []); 

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="wpo-blog-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            {blogs.map((blog, bitem) => (
                                <div className={`post `}key={bitem}>
                                    <div className="entry-media video-holder">
                                        <img src={`${API}uploads/images/${blog['news_images'][0]['image.src']}`} alt=""/>
                                        {/* <VideoModal/> */}
                                    </div>
                                    <div className="entry-meta">
                                        <ul>
                                            <li><i className="fi flaticon-user"></i> By <Link onClick={ClickHandler} to={`/news-single/${blog.id}`}>{blog.author}</Link> </li>
                                            
                                            <li><i className="fi flaticon-calendar"></i> {moment(blog.created_at).format('YYYY-MM-DD')}</li>
                                        </ul>
                                    </div>
                                    <div className="entry-details">
                                        <h3><Link onClick={ClickHandler} to={`/news-single/${blog.id}`}>{blog.title}</Link></h3>
                                        <p>{blog[`title_${currentLanguage}`] || blog.title_uz}</p>
                                        <Link onClick={ClickHandler} to={`/news-single/${blog.id}`} className="read-more">{t('readMore1')}</Link>
                                    </div>
                                </div>
                            ))}

                             
                        </div>
                    </div>
                    <BlogSidebar blLeft={props.blLeft}/>
                </div>
            </div>
        </section>

     )
        
}

export default BlogList;
